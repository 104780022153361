import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import {setTitle} from '@/utils'

Vue.use(Router)

// hack 消除使用replace同名路由是产生的警告
const VueRouterPush = Router.prototype.replace
Router.prototype.replace = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const router = new Router({
  mode: 'hash',
  routes
})

router.afterEach(() => {})

// document title change
router.afterEach(to => {
  if (!to.meta || !to.meta.title) return false
  setTitle(to.meta.title)
})

export default router
