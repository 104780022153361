export const setTitle = title => {
  const iframeLoad = function(src) {
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = src
    document.body.appendChild(iframe)
    iframe.addEventListener('load', function() {
      setTimeout(function() {
        iframe.remove()
      }, 0)
    })
  }
  document.title = title
  if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    const src = 'http://qq.com?' + Math.random()
    iframeLoad(src)
  }
}

export const doubleNum = num => {
  return num > 9 ? num + '' : '0' + num
}

export const getUrlParams = href => {
  let url = href || location.href
  if (url.indexOf('#') >= 0) {
    url = url.substr(0, url.indexOf('#'))
  }
  var params = url.substr(url.indexOf('?') + 1)
  params = params.split('&')
  var obj = {}
  for (var i = 0; i < params.length; i++) {
    var tem = params[i].split('=')
    obj[tem[0]] = decodeURIComponent(tem[1])
  }
  return obj
}

export const UrlUpdater = url => {
  var oldUrl = url

  var hash = ''
  var hashIndex = url.indexOf('#')
  if (hashIndex >= 0) {
    hash = url.substr(hashIndex)
    url = url.substr(0, hashIndex)
  }

  var questionIndex = url.indexOf('?')
  var params = {}
  if (questionIndex >= 0) {
    params = getUrlParams(oldUrl)
    url = url.substr(0, questionIndex)
  }
  return {
    updateHash: function(newHash) {
      hash = newHash
    },
    addQuery: function(key, value) {
      params[key] = value
    },
    removeQuery: function(key) {
      delete params[key]
    },
    build: function() {
      var newUrl = url
      var queryString = ''
      for (var key in params) {
        queryString = queryString || '?'
        queryString += key + '=' + encodeURIComponent(params[key]) + '&'
      }
      newUrl += queryString ? queryString.substr(0, queryString.length - 1) : ''
      newUrl += hash
      return newUrl
    }
  }
}

export const uuid = (len, radix) => {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  var uuid = []
  var i = null
  radix = radix || chars.length

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
  } else {
    // rfc4122, version 4 form
    var r

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16)
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
      }
    }
  }

  return uuid.join('')
}

function random(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

export const maskCode = (serverTime, localTime) => {
  const ts = +new Date() - localTime + serverTime
  return `${random(1000, 9999)}${ts + 786795436012}-${uuid(Math.ceil(Math.random() * 10))}`
}